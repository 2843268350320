import * as React from "react";
import styled from 'styled-components';
import Layout from "../components/layout";
import SEO from "../components/seo";
import BannerSvg from '../assets/Vesta-05.svg';
import Services from '../assets/services.svg';
import Portfolio from '../assets/portfolio.svg';
import { colours } from "../global/colours"
import { ServiceCards } from "../components/service-card"
import { PortfolioGallery } from "../components/portfolioGallery"

const BannerSection = styled.div`
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  align-items: center;
  & > div {
    min-width: 300px;
    flex: 4;
    padding-right: 30px;
    height: max-content;
    h4 {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 50px;
    }
  }
  .bannerSvg {
    flex: 5;
    min-width: 300px;
  }
`;

const SVGWrapper = styled.div`
  svg {
    fill: ${colours.blue};
    opacity: 0.4;
    margin: -60px 0;
    width: 101%;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <BannerSection className="content">
      <div>
        <h4>Hi,</h4>
        <p>I’m a Lead UI Developer for <a href="https://www.scalable.com/">Scalable</a> and co-founder for <a href="https://www.luckynightstudio.co.uk/">Lucky Night Studio</a></p>
        <p>If your interested in working with me, please reach out to <a href="https://www.luckynightstudio.co.uk/" style={{ color: 'rgb(253, 128, 141))', fontWeight: 600 }}>Lucky Night Studio</a></p>
      </div>
      <BannerSvg className="bannerSvg"/>
    </BannerSection>
    <SVGWrapper>
      <Services/>
    </SVGWrapper>
    <div className="content">
      <ServiceCards />
    </div>
    <SVGWrapper>
      <Portfolio />
    </SVGWrapper>
    <PortfolioGallery />
  </Layout>
)

export default IndexPage
