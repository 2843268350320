import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
// @ts-ignore
import ClipPath from "../assets/clipPath.svg"
import { Data, PortfolioCard } from "./portfolioCard"
import styled from "styled-components"
import { Button } from "./button"

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
   @media (min-width: 700px) {
    margin-top: -40px;
  }
`

export const PortfolioGallery = () => {
  const { allAirtable } = useStaticQuery(
    graphql`
      query {
        allAirtable(limit: 3) {
          nodes {
            data {
              Name
              Platform
              Services
              Thumbnail {
                localFiles {
                  childImageSharp {
                    fluid(maxHeight: 400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const { nodes }: { nodes: Node[] } = allAirtable
  return (
    <div className="content">
      <ClipPath />
      <Container>
        {nodes.map(({ data }, index) => (
          <div key={index}>
            <PortfolioCard {...data} />
          </div>
        ))}
      </Container>
      <Button link='/portfolio' text='Portfolio'/>
    </div>
  )
}

interface Node {
  data: Data
}
