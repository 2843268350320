import * as React from "react"
import styled from "styled-components"
import { colours } from "../global/colours"
// @ts-ignore
import WebDesignSvg from "../assets/webdesign.svg"
// @ts-ignore
import AppDesignSvg from "../assets/appdesign.svg"
import { Button } from "./button"

interface ServiceData {
  header: string
  description: string
  Img: any
}

const servicesData: ServiceData[] = [
  {
    header: "Website Design & Development",
    description:
      "I design and develop custom websites for static, e-commerce sites, or create a Shopify Theme for your business",
    Img: WebDesignSvg,
  },
  {
    header: "App Design & Development",
    description:
      "I can design and develop custom apps for android, iOS, windows, or macOS",
    Img: AppDesignSvg,
  },
]
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  margin-bottom: 80px;
`

export const ServiceCards = () => {
  return (
    <>
    <Container>
      {servicesData.map(({ header, description, Img }: ServiceData, index: number) => (
        <ServiceCard header={header} description={description} Img={Img} key={index} />
      ))}
    </Container>
      </>
  )
}

const Card = styled.div`
  max-width: 400px;
  box-shadow: 2px 2px 6px #eee;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  margin: 20px;
  .cardHeader {
    background: ${colours.navy};
    color: ${colours.white};
    padding: 20px;
    padding: 10px;
    padding: 10px;
    text-align: center;
    border-radius: 20px 20px 0 0;
  }
  .cardContent {
    padding: 20px;
    border-radius: 0 0 20px 20px;
  }
`

const ServiceCard = ({ header, description, Img }: ServiceData) => {
  return (
    <Card>
      <div className="cardHeader">
        <h3>{header}</h3>
      </div>
      <div className="cardContent">
        <p>{description}</p>
      </div>
      <Img style={{ maxHeight: 300 }} />
    </Card>
  )
}
