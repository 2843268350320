import Img, { FluidObject } from "gatsby-image"
import * as React from "react"
import styled from "styled-components"
import { colours } from "../global/colours"
import { Link } from "gatsby"

export const PortfolioCard = ({
  Name,
  Thumbnail,
  Platform,
  Services,
}: Data) => {
  const services = Services.split("\n").filter(str => str)
  return (
    <Link to={`/portfolio/${Name.replace(/\s/g, '_')}`}>
      <Card>
        <div className="portfolioCardBlob"/>
        <div className="portfolioLabel">
          <h3>{Name}</h3>
          <p>{Platform}</p>
          <div className="servicesList">
            {services.map((service, i) => (
              <p key={i}>{service}</p>
            ))}
          </div>
        </div>
        <Img fluid={Thumbnail.localFiles[0].childImageSharp.fluid} style={{height: 400, width: 300}}/>
      </Card>
    </Link>
  )
}

export interface Data {
  Name: string
  Platform: string
  Services: string
  Thumbnail: {
    localFiles: [
      {
        childImageSharp: {
          fluid: FluidObject
        }
      }
    ]
  }
}

const Card = styled.div`
  width: 300px;
  height: 400px;
  @media (max-width: 800px) {
     width: 250px;
    height: 350px;
    font-size: 14px;
  }
  @media (max-width: 680px) {
    width: 320px;
  }
  position: relative;
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 2px 6px #eee;
  .servicesList {
    opacity: 0;
    transition: 0.8s;
    padding-top: 20px;
    p {
      margin-bottom: 0;
    }
  }
  .portfolioCardBlob {
    background: ${colours.blue};
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 60%;
    clip-path: url(#clipPath);
  }
  .portfolioLabel {
    width: 60%;
    transition: width 0.8s;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    color: ${colours.navy};
    padding: 10px;
    text-align: center;
    h3 {
      margin: 10px 0;
      color: ${colours.white};
    }
  }
  &:before {
    content: "";
    position: absolute;
    border-radius: 30% 70% 30% 30% / 30% 30% 70% 70%;
    background: ${colours.blue};
    height: 45%;
    width: 40%;
    top: 0;
    right: 0;
    z-index: 2;
    transition: 0.6s;
  }
  &:hover {
    .servicesList {
      opacity: 1;
    }
    .portfolioLabel {
      width: 100%;
    }
    &:before {
      border-radius: 15% 40% 50% 60% / 25% 0% 50% 30%;
      height: 100%;
      width: 100%;
    }
  }
`
